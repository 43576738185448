
import { currency, slash } from "@/filters/utils";
import { AddInput, InputTypes } from "@/store/modules/input/input.types";
import { DefaultState, Pagination } from "@/types/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const input = namespace("Input");

@Component({
  components: {
    NoData: () => import("@/components/utils/NoData.vue"),
  },
  filters: {
    currency,
    slash,
  },
})
export default class InputsView extends Vue {
  @Prop({ default: true }) pagination?: boolean;
  @Prop({ default: "" }) query?: string;

  @input.State("inputs")
  public inputs!: AddInput[];

  @input.State("add")
  public addInput!: DefaultState;

  @input.State("pages")
  public pageCount!: number;

  @input.State("loadingInput")
  public loadingInputs!: boolean;

  @input.Mutation(InputTypes.SET_ADD_INPUT_DIALOG)
  public setAddInput!: (addInput: boolean) => void;

  @input.Action(InputTypes.LOAD_INPUTS)
  public loadInputs!: (pagination: Pagination) => void;

  public page = 1;

  @Prop({ required: true, default: 10 }) public pageSize!: number;

  public headers = [
    {
      text: "NAME",
      sortable: true,
      value: "name",
    },
    {
      text: "UNITS",
      sortable: true,
      value: "unit",
    },
    {
      text: "CATEGORY",
      sortable: true,
      value: "category",
    },
    {
      text: "PACKAGE",
      sortable: true,
      value: "package",
    },
    {
      text: "COMPANY",
      sortable: true,
      value: "company",
    },
  ];

  closeAddInputDialog(): void {
    this.setAddInput(false);
  }

  openAddInputDialog(): void {
    this.setAddInput(true);
  }

  @Watch("pageSize")
  handlePageSizeChange(): void {
    this.page = 1;
    this.queryInputs();
  }

  @Watch("page")
  handlePageChange(): void {
    this.queryInputs();
  }

  queryInputs(): void {
    this.loadInputs({
      page: this.page,
      limit: this.pageSize,
      query: this.query,
    });
  }

  mounted(): void {
    if (this.inputs.length == 0) this.queryInputs();
  }
}
